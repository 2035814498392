<div class="wrapper row2">
    <div id="container" class="clear">

        <section id="fof" class="clear">
            <div class="positioned">
                <div class="hgroup clear">
                    <h1>404</h1>
                    <h2>Page Not Found !</h2>
                </div>
                <p>For Some Reason This Page Did't Found On Our Server</p>
            </div>
            <!-- <p class="clear">
                <a class="go-back" href="javascript:history.back()">&laquo; Go Back</a>
                <a class="go-home" href="/login">Go Home &raquo;</a>
            </p> -->
        </section>

    </div>
</div>