import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from '../services/common.service';

@Component({
  selector: 'app-no-internet',
  templateUrl: './no-internet.component.html',
  styleUrls: ['./no-internet.component.scss']
})
export class NoInternetComponent implements OnInit {

  online: boolean = false;
  isNetworkStopped: boolean = false;

  constructor(private service: CommonService, private router: Router) { }

  ngOnInit() {
    this.online = window.navigator.onLine;

    let lastViewed = this.service.session('get','lastViewedUrl');// Get The User Last Viewed Url

    if (this.online && lastViewed != null) {
      this.router.navigate([lastViewed]);
    }
    else if (this.online && lastViewed == null) {
      this.router.navigate(['root/dashboard']);
    }
  }

  chcekNow() {
    this.online = window.navigator.onLine;

    let lastViewed = this.service.session('get','lastViewedUrl'); // Get The User Last Viewed Url

    if (this.online) {
      if (lastViewed != null) {
        this.router.navigate([lastViewed]);
      }
      else if (lastViewed == null) {
        this.router.navigate(['root/dashboard']);
      }
    }
    else {
      this.service.showToastr('Sorry! no connections found', 'warning');
    }

  }
}
