import { Component, AfterViewInit, Compiler  } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from './shared/services/common.service';
import { ConnectionService } from 'ng-connection-service';
// in app.component.ts
import { LocationStrategy } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {

  title = 'smartbridge';
  showSideNavButton: boolean = false;
  show_branches: boolean = false;
  selectedBranches: Array<string> = [];
  currentPage: string = '';
  isConnected = true;

  constructor(private service: CommonService, private router: Router, private connectionService: ConnectionService, private location: LocationStrategy, private _compiler: Compiler) {
    this._compiler.clearCache();
    this.connectionService.monitor().subscribe(isConnected => {
      this.isConnected = isConnected;
      if (this.isConnected) {
        let lastViewed = this.service.session('get','lastViewedUrl'); // Get The Last Viewed Url
        this.service.session('remove','lastViewedUrl'); // Remove The Last Viewed Url From Local Storage
        if (lastViewed != null) // Last Viewed Url When Available
        {
          if (lastViewed == 'root/no-internet') // If Last Url is Check Internet Connection Page
          {
            this.router.navigate(['root/dashboard']); // Redirect To The Dashboard
          }
          else if (lastViewed != 'root/no-internet') {
            this.router.navigate([lastViewed]); // Redirect To The Last Viewed Url
          }
        }
      }
      else {
        let lastViewed = this.service.session('get','lastViewedUrl'); // Get The User Last Viewed Url
        if (lastViewed == null) // If The Last Viewed Url Is Not Present
        {
          this.router.navigate(['root/no-internet']); // Redirect The Check Internet Connection Page
          this.service.session('set','lastViewedUrl', this.router.url); // Set the Current Url is Last Viewed 
        }
      }
    })
    this.router.events.subscribe(val => {
      this.currentPage = this.router.url.split('/').indexOf('dashboard') != -1 ? 'dashboard' : '';
      if (this.currentPage != 'dashboard') {
        this.service.showMobileViewSidenavButton({ view: false, branchList: [] });
      }
    });
    this.service.sidenavButtonDetails.subscribe((data: any) => {
      this.showSideNavButton = data.view && this.currentPage == 'dashboard';
      this.selectedBranches = data.branchList || [];
      this.show_branches = !this.showSideNavButton ? false : this.show_branches;
    });
  }

  scrollTop() {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }

  ngAfterViewInit() {
    let themeMode = this.service.session('get','theme-mode');
    // // Wait for document to load
    // document.addEventListener("DOMContentLoaded", function(event) {
      document.documentElement.setAttribute("data-theme", themeMode || 'light');
    // });
  }
}
