
<router-outlet></router-outlet>

<div *ngIf="showSideNavButton" class="sideNavDiv" (click)="show_branches=!show_branches" [ngClass]="{ 'show-branch' : show_branches}">
    <div class="branches" cdkDrag>
        <div class="header-sec sub-header-sub-menu d-flex align-items-center">
            <div class="branch-image">
                <img src="./assets/img/{{ !show_branches ? 'branches' : 'close' }}.png">
            </div>
            <span (click)="scrollTop()">Branches <i class="icon icon-note" [ngClass]="!show_branches?'mx-1':'mx-2'"></i></span>
        </div>
        <ul *ngIf="selectedBranches.length>0">
            <li class="sub-header-sub-menu" *ngFor="let item of selectedBranches">{{item}}</li>
        </ul>
    </div>
</div>