<div class="check-internet">

    <div class="container d-flex justify-content-center" style="height:100vh;">

        <div class="row justify-content-center align-self-center">

            <div class="col">

                <div class="card">

                    <div class="card-header">

                        <h2 class="text-left font-weight-bold mb-0"> No internet connection 

                            <i class="fa fa-plug float-right h2 mb-0" aria-hidden="true"></i>

                        </h2> 
                                            
                    </div>

                    <div class="card-body px-sm-4 mb-3">

                        <ul class="list-unstyled text-muted">

                            <li class="mb-2 h6">Please re-connect to the internet to continue use Footsteps.</li>
                            <li class="mb-2 h6">If you encounter problems:</li>

                            <ul class="mt-3 inner">

                                <li class="mb-2">Try restarting wireless connection on this device.</li>
                                <li class="mb-2">Move clouse to your wireless access point.</li>

                            </ul>

                        </ul>

                        <div class="row justify-content-end mt-4 ">
                            <div class="col-auto">
                                <button type="button" (click)="chcekNow()" class="btn btn-success">
                                    <span class="h6">Try Again</span>
                                </button>
                            </div>
                        </div>

                    </div>

                </div>

            </div>                                                                  

        </div>

    </div>

</div>    