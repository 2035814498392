export { } // this will make it module

declare global{ // this is important to access it as global type String
    interface String {
        toTitleCase(): String;
    }
    interface Number {
        toINR(): String;
    } 
    interface Date {
        getWeek(): Number;
    }
    interface Array<T> {
        removeDuplicates(dataType: String, compareKey: String): Array<any>;
    }

    interface String {
        stringToFloat(): Number;
    }

    interface Array<T> {
        unwind(unwindBy: string): Array<any>;
    }
}

Date.prototype.getWeek = function() {
    var onejan: any = new Date(this.getFullYear(),0,1);
    var today: any = new Date(this.getFullYear(),this.getMonth(),this.getDate());
    var dayOfYear = ((today - onejan + 86400000)/86400000);
    return Math.ceil(dayOfYear/7)
};

Array.prototype.removeDuplicates = function (dataType: 'string' | 'object', compareKey?: string) {
    let result = [];
    switch(dataType) {
        case 'string':
            result = this.filter((item, index, self) =>{ return self.indexOf(item) == index; });
            break;
        case 'object':            
            result =  this.filter((item, index, self) => index === self.findIndex((t) => ( t[compareKey] === item[compareKey] )));
            break;
        default:
            result = [];
            break;
    }
    return result;
};

String.prototype.toTitleCase = function () {
    return this.replace(/\w\S*/g, (txt) => { return txt ? txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase() : ""; } );
};

Number.prototype.toINR = function () {
    const d = (this || 0).toString().replace(/(\d+?)(?=(\d\d)+(\d)(?!\d))(\.\d+)?/g, "$1,");
    const b = '\u20B9';
    return b.concat(d);
};

String.prototype.stringToFloat = function () {

    return parseFloat((this).replace(/,/g, '') || 0)

}

Array.prototype.unwind = function (unwindBy) {

    return this.reduce((r, o) => r.concat(o[unwindBy].map(v => ({ ...o, [unwindBy]: v }))), [])

}