import { BrowserModule } from '@angular/platform-browser';
import { DatePipe, DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { Error404Component } from './shared/error404/error404.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { Interceptor } from './shared/interceptors/interceptor';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NoInternetComponent } from './shared/no-internet/no-internet.component';
import { CommonToasterComponent } from './shared/common-toaster/common-toaster.component';
import { CookieService } from 'ngx-cookie-service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import './shared/utils/prototypes';

@NgModule({
  declarations: [
    AppComponent,
    Error404Component,
    NoInternetComponent,
    CommonToasterComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    HttpClientModule,
    BrowserAnimationsModule,
    DragDropModule,
    FormsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot({
      positionClass: "toast-top-right",
      toastComponent: CommonToasterComponent,
      timeOut: 300000,
      preventDuplicates: true
    })
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: Interceptor, multi: true }, DatePipe, DecimalPipe, CookieService
  ],
  bootstrap: [AppComponent],
  entryComponents: [CommonToasterComponent]
})
export class AppModule { }
