import { Component } from '@angular/core';
import { Toast, ToastrService, ToastPackage } from "ngx-toastr";
import { ToastrAnimation } from '../animations/slide-in-out-animations';

@Component({
  selector: 'app-common-toaster',
  templateUrl: './common-toaster.component.html',
  styleUrls: ['./common-toaster.component.scss'],
  animations: ToastrAnimation
})
export class CommonToasterComponent extends Toast {

  constructor
  (
    protected toastrService: ToastrService,
    public toastPackage: ToastPackage
  ) {
    super(toastrService, toastPackage);
  }
}
