import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { CommonService } from '../services/common.service';

@Injectable({
  providedIn: 'root'
})
export class LoginAuthGuard implements CanActivate {
    companyId: string = "";  
    constructor( private service: CommonService, private router: Router ) { }
    canActivate(
      next: ActivatedRouteSnapshot,
      state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        let token = this.service.session('get','Authorization');
        if(token) {
          this.router.navigate(['/root']);
          let accessCheck = JSON.parse(this.service.session('get','Permissions'));
          for (var i = 0; i < accessCheck.length; i++) {
            if (accessCheck[i].allow != false) {
              this.router.navigate(['/root/' + accessCheck[i].mainMenu]);
              return false;
            }
          }
        }
        else {
          if(this.companyId!==state.url.split('/')[1]) {
            this.companyId = state.url.split('/')[1];
            let img = this.service.baseUrl+"/companyLogo/"+this.companyId+'.png';
            let textimg = new Image();
            textimg.onload = (()=>{
              this.router.navigate(['/'+this.companyId]);
              return true;
            });
            textimg.onerror = (()=>{
              this.router.navigate(['/404/invalidurl']);
              return false;
            });
            textimg.src = img;
          }
          else {
            return true;
          }
        }
    }
}