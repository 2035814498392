
export const environment = {

  production: false,
  encryptedReq: false,
  
  // staging server url's
  domain: 'https://dms.cbotstech.com/api/v1',
  socket: 'wss://dms.cbotstech.com',
  imgUrl: 'https://dms.cbotstech.com/vehiclePics/',

  // production server url's
  // domain: 'https://backend.cbotsolution.com/api/v1',  
  // socket: 'wss://backend.cbotsolution.com',
  // imgUrl: 'https://backend.cbotsolution.com/vehiclePics/',


  // Specific Host 

  // socket: 'ws://192.168.0.100:8500',
  // domain: 'http://192.168.0.100:8500/api/v1',
  // imgUrl: 'http://192.168.0.100:8500/vehiclePics/',

  // local server url's 
  // socket: 'ws://localhost:8500',
  // domain: 'http://localhost:8500/api/v1',
  // imgUrl: 'http://localhost:8500/vehiclePics/',

  secretKey: "track#0071#", 
  pay: 'rzp_live_bc6szCNrsxbXT9',
  logoUrl: 'https://cbotsolution.com/assets/images/cbot.png',
  
};    
