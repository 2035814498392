import { trigger, state, style, transition, animate, group, sequence, keyframes } from '@angular/animations';

export const SlideInOutAnimation = [
  trigger('anim', [
    transition('* => void', [
      style({ height: '*', opacity: '1', transform: 'translateX(0)', 'box-shadow': '0 1px 4px 0 rgba(0, 0, 0, 0.3)'}),
      sequence([
        animate(".25s ease", style({ height: '*', opacity: '.2', transform: 'translateX(20px)', 'box-shadow': 'none'  })),
        animate(".1s ease", style({ height: '0', opacity: 0, transform: 'translateX(20px)', 'box-shadow': 'none'  }))
      ])
    ]),
    transition('void => active', [
      style({ height: '0', opacity: '0', transform: 'translateX(20px)', 'box-shadow': 'none' }),
      sequence([
        animate(".1s ease", style({ height: '*', opacity: '.2', transform: 'translateX(20px)', 'box-shadow': 'none'  })),
        animate("1s ease", style({ height: '*', opacity: 1, transform: 'translateX(0)', 'box-shadow': '0 1px 4px 0 rgba(0, 0, 0, 0.3)'  }))
      ])
    ])
  ])
];

export const ZoomAnimation = [
  trigger("zoom", [
    state("rightTop", style({transform: 'scale(0.5)', transformOrigin: 'right top', opacity: '0'})),
    state("rightBottom", style({transform: 'scale(0.3)', transformOrigin: 'right bottom', opacity: '0'})),
    state("leftTop", style({transform: 'scale(0.3)', transformOrigin: 'left top', opacity: '0'})),
    state("leftBottom", style({transform: 'scale(0.3)', transformOrigin: 'left bottom', opacity: '0'})),
    state("default", style({transform: 'scale(1)', opacity: '1'})),
    transition("* <=> default", animate( "2000ms cubic-bezier(0.25,1,.25,1)" ))
  ]),
  trigger("fadeInOut", [
    state("rightTop", style({ opacity: '1'})),
    state("default", style({opacity: '0'})),
    transition("* <=> default", animate( "2000ms cubic-bezier(0.25,1,.25,1)" ))
  ])
];

export const ToastrAnimation = [
  trigger("flyInOut", [
    state("inactive", style({ opacity: 0 })),
    transition("inactive => active", 
      animate( "600ms ease-out", 
        keyframes([ 
          style({ transform: "translate3d(0, 100%, 0)", opacity: 0 }), 
          style({ transform: "none", opacity: 1 }) 
        ]) 
      ) 
    ),
    transition("active => removed",
      animate("400ms ease-out",
        keyframes([
          style({ opacity: 1 }),
          style({ transform: "translate3d(0,100%, 0)", opacity: 0 })
        ])
      )
    )
  ])
]
