import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { catchError, map } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { throwError, Observable } from "rxjs";
import { ToastrService } from "ngx-toastr";
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  public baseUrl: string = environment.domain;

  constructor(private http: HttpClient, private toastr: ToastrService) { }

  // POST API Method While Pass JSON Data
  postService(url, data?: any, params?:any): any {
    
    if(environment.encryptedReq) {
      data = { "data" : this.encryptData(JSON.stringify(data)) };
      url = this.encryptData(url);
    }

    return this.http.post(this.baseUrl + url, data, { "params": params }).pipe(
      map((res) => res),
      catchError((err) => throwError(err))
    );
  }

  // UPDATE API Method
  updateService(url: string, data?: any, params?:any): any {

    if(environment.encryptedReq) {
      data = { "data" : this.encryptData(JSON.stringify(data)) };
      url = this.encryptData(url);
    }

    return this.http.patch(this.baseUrl + url, data, { "params" : params}).pipe(
      map((res) => res),
      catchError((err) => throwError(err))
    );
  }

  // GET API Method  
  getService(url: string, params?:any): any {

    if(environment.encryptedReq)
      url = this.encryptData(url);

    return this.http.get(this.baseUrl + url, { "params" : params}).pipe(
      map((res) => res),
      catchError(err => throwError(err))
    );
  }

  // DELETE API Method
  deleteService(url: string): any {

    if(environment.encryptedReq)
      url = this.encryptData(url);    

    return this.http.delete(this.baseUrl + url).pipe(
      map((res) => res),
      catchError((err) => throwError(err))
    );
  }

  // POST Method While Pass Form Data
  postFile(url: string, formData): any {

    if(environment.encryptedReq)
      url = this.encryptData(url);    

    return this.http.post(this.baseUrl + url, formData).pipe(
      map((res) => res),
      catchError((err) => throwError(err))
    );
  }

  // GET Method While Getting File
  getFile(url: string): Observable<Blob> {

    if(environment.encryptedReq)
      url = this.encryptData(url);    
      
    return this.http.get(this.baseUrl + url, { responseType: "blob" }).pipe(
      map((res) => res),
      catchError((err) => throwError(err))
    );
  }

  // Download All Files In This Application
  downloadFiles(fileName, fileExtension, fileURL) {
    var fileType = "";
    // Set the File Type Based on the File Extension
    if (fileExtension == "xlsx" || fileExtension == "csv") {
      fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    }
    this.getFile(fileURL).subscribe(
      (res) => {
        const newBlob = new Blob([res], { type: fileType });
        const data = window.URL.createObjectURL(newBlob);
        const link = document.createElement("a");
        link.href = data;
        link.download = fileName + "." + fileExtension; // Set the Download File Name
        link.click();
        this.toastr.success("Downloaded success!");
      },
      (error) => {
        this.toastr.error("Report Generated Error!");
      }
    );
  }

  // Encrypt the passing plain text using crypto js encryption
  encryptData(plainText: string): string {
    let encryptedData = CryptoJS.AES.encrypt(plainText, environment.secretKey).toString();
    return encryptedData;
  }

  // Decrypt the passing plain text using crypto js decryption
  decryptData(encryptedText: string): string {
    var decryptedData = CryptoJS.AES.decrypt(encryptedText, environment.secretKey).toString(CryptoJS.enc.Utf8);
    return decryptedData;
  }
}
