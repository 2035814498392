import { Injectable } from "@angular/core";
import { DatePipe } from "@angular/common";
import { Router } from '@angular/router';
import { Observable, Subject, BehaviorSubject, forkJoin } from "rxjs";
import { ToastrService } from "ngx-toastr";
import jwt_decode from 'jwt-decode';
import { ApiService } from './api.service';
import { Location } from '@angular/common';
import { UtilsService } from '../utils/utils.service';
import * as _ from 'lodash';
import * as XLSX from 'xlsx';
import * as ExcelJS from 'exceljs';
import * as fs from 'file-saver';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: "root",
})
export class CommonService {

  // Common Fuel Types , Transmission Types
  downloadPermission = true;
  menu = [];
  public baseUrl: string = this.apiservice.baseUrl;
  public inventoryStatus: Array<any> = [ { status: "stock" }, { status : "gstock" },  { status: "allocated" },{ status : "sold unallocated" }, { status: "sold" },  { status : "delivered" } ];
  public rowsPerPageArray: Array<number> = [10, 15, 20, 25];
  teamItems: Subject<any> = new Subject<any>();
  userItems: Subject<any> = new Subject<any>();
  branchesIdListItems: Subject<any> = new Subject<any>();
  notificationActionEvent: any = new BehaviorSubject(null);
  modelList: any = [];
  fuelList: any = [];
  transmissionList: any = [];
  vendorList: any = [];
  wareHouseLocationList: any = [];
  teamList: any = [];
  loginUsersList: any = [];
  financeOptionsList: any = [];
  bookingStatusList: any = [];
  financeExecutives: any = [];
  financierList: any = [];
  customerType: any = [];
  reportTypes: any = [];
  navigationStartPage: string = '';
  authToken: any = "";
  editUsermode: boolean = false;
  financeReqDocuments: Array<string> = ['Passport Card', 'Bank Statement ( 6 MOS)', 'IT Return', 'Aadhaar Card', 'TRN Copy', 'Cheque', 'Photos'];
  auth: any;
  myBalance: any;
  editUserItems = new BehaviorSubject<any>([]);
  bookingDataList = new BehaviorSubject<any>([]);
  sidenavButtonDet = new BehaviorSubject<any>({});
  editUserDetails = this.editUserItems.asObservable();
  bookingDetails = this.bookingDataList.asObservable();
  sidenavButtonDetails = this.sidenavButtonDet.asObservable();
  name = 'This is XLSX TO JSON CONVERTER';
  willDownload = false;
  maxDate: string;
  listBranches: any = [];
  menuBranchList: any;
  listBranchId: any = [];
  getbranchIds: any;
  public branchAccessDetails: any = branchAccessDetails;
  public companyList: any = companyList;
  public branchList: any = branchList;
  public teamListItems: any = teamListItems;
  public inrSymbol: string = "₹";
  public randomColor: Array<any> = ["cadetblue","aquamarine","brown","burlywood","crimson","yellowgreen","violet","turquoise","tomato","teal","steelblue","slategrey"]
  selectedBranches: Array<string> = [];
  selectedbranchAccessDet: any;
  bookingSubMenuPermission: any[];
  bookingSubMenuItem = new BehaviorSubject<any>([]); statesList: any;
  cityId: any;
  actionItems: any;
  maxYear: any;
  relationshipList: any = [];
  public isLoading = new BehaviorSubject(false);
  public loaderApiUrls = new BehaviorSubject<any>([]);
  public sourceOfBookingsList = new BehaviorSubject<any>([]);
  public editBookingData: any;
  departmentFilterItems: any = [];
  public notificationList = new BehaviorSubject<any>(null);
  playNotificationAudio: boolean = true;
  notificationAudio = new Audio();
  companyDetails: any;
  companyDetailItems = new Subject<any>();
  public notificationActions: any = new Object();
  public isTeamLead: boolean = false;
  public isCA: boolean = false;
  public userDetails: any = {};
  public userRoleDet = {
    isCA       : false,
    isTeamLead : false,
    isFinExec  : false,
    isFinMngr  : false,
    isTdm      : false
  };
  permissionsDet: any = [];

  currentpriceDet: Array<any> = [];

  vehilceImgeUrl : String =  environment.imgUrl;
  defaultVehImg = "default";

  testdriveConfg : any = {

    "startTime" : null,

    "endTime" : null,

    "slabVariance" : null
  };
  
  constructor(
    private router: Router,
    private toastr: ToastrService, private datePipe: DatePipe, private apiservice: ApiService, private location: Location, private utils: UtilsService ) {

      this.setMaxYear();

      this.notificationAudio.src = "../../../assets/audio/notification-alert.mp3";

      // Setting the Company details to companyProfile Global Variable for Refreshing the pages

      if(JSON.parse(this.session('get',"activateUser"))) {

        this.loadAccessDetails();

        this.setCompanyProfile(); 

      }

  }

  // Getting common values
  getLoginValues() {

    if(JSON.parse(this.session("get","Permissions"))?.filter((menu:any)=>(menu?.mainMenu == "bookings")).length > 0){

      let permissions = JSON.parse(this.session("get","Permissions"))?.filter((menu:any)=>(menu?.mainMenu == "bookings"))[0]['subMenu'];
      let accessBranches = permissions.filter((obj:any)=>(obj?.childMenu == "list"))[0].branches.filter((branch:any)=>(branch?.permission == "edit")).map(b=>b?.branchId);
  
      // Common Api list
      let apiArr = [
        this.postService("/booking/rtoStatus", {branchId : accessBranches}), 
        this.getService("/master/paymentModes"),
        this.getService("/master/banks")
      ];
  
      forkJoin(apiArr).subscribe((responseArr: any) => {
        if(responseArr.every(res=>res.status==200)) {
          // Store RTO Status on Local storage
          this.constructRTOStatus(responseArr[0].results);
          // Store Mode of payments
          this.setPaymentModes(responseArr[1].results);
  
          // Store banks
          this.session("set","banks",JSON.stringify(responseArr[2].results));
  
        }},
        (error : any)=>{        
          this.showToastr('Sorry, some api has an issues',"error");
        });

    }
  
  }

  getSlabVarianceList() {

    this.postService('/testdrive/slabs').subscribe((res: any)=>{
      
        if(res.status == 200){

          let result = res.results;

         sessionStorage.setItem("tdConfig", JSON.stringify(res.results))

        }
    },err=>{

      this.showToastr(err.error.message, 'error');

    });

  }
  // Construct RTO Status by Booking List edit branches
  constructRTOStatus(statusList : Array<any>){
    let statusEnem = new Object();
    statusList.forEach((status)=>{
      statusEnem[status?.branchId] = status?.status;
    }); 
    this.session("set","rtoStatus",JSON.stringify(statusEnem));
  }

  // Store payment ModeList on Local Storage
  setPaymentModes(paymentModeList : Array<any>){ 
    this.session("set","paymentModes",JSON.stringify(paymentModeList));
  }

  setCompanyProfile() {

    if(this.session('get','companyDetails')) {

      this.companyDetails = JSON.parse(this.session('get','companyDetails'));   

      this.companyDetailItems.next(this.companyDetails);

      this.getBranchList();

      this.departmentFilterItems = [];

    }

  }
  
  // Load menu details and branch details
  loadAccessDetails() {

    // Get Permissions From Local Storage
    this.auth = JSON.parse(this.session('get',"activateUser"));    

    this.menu = JSON.parse(this.session('get',"Permissions"));

    this.userDetails = JSON.parse(this.session('get',"userAddInfo"));

    this.permissionsDet = JSON.parse(this.session('get',"permissionDetails"));

    this.isTeamLead = JSON.parse(this.session('get','Action_items')).filter(item => item.menu == 'tl').length > 0;

    this.isCA = JSON.parse(this.session('get','Action_items')).filter(item => item.menu == 'caExe').length > 0;

    JSON.parse(this.session('get',"notificationActions"))?.forEach((element: any) => {

      this.notificationActions[element.action] = element.function;

    });

    this.actionItems = JSON.parse(this.session('get',"Action_items")) || [];
    
    this.userRoleDet = { 

      isCA : this.actionItems.filter((item:any)=>(item?.menu == "caExe" && item?.allow)).length > 0,

      isTeamLead : this.actionItems.filter((item:any)=>(item?.menu == "tl" && item?.allow)).length > 0,

      isFinExec : this.actionItems.filter((item:any)=>(item?.menu == "finExe" && item?.allow)).length > 0,

      isFinMngr : this.actionItems.filter((item:any)=>(item?.menu == "finMngr" && item?.allow)).length > 0,

      isTdm : this.actionItems.filter((item:any)=>(item?.menu == "tdm" && item?.allow)).length > 0,

    };
    
  }

  loadEnumerators() {

    let array = new Object();

    let branchListObj = new Object();

    let companyListObj = new Object();
    
    this.listBranchId.forEach((obj: any, index: number) => {

      array[obj.branchId] = this.getBranchPermissions(obj.branchId);

      branchListObj[obj.branchId] = obj.branchName;

      companyListObj[obj.companyId] = obj.companyName;

    });

    this.branchAccessDetails = array;

    this.branchList = branchListObj;

    this.companyList = companyListObj;

  }

  // Read Branch Wise Permissions

  getBranchPermissions(branchId: string): any {

    let returnObject = {};

    this.menu.forEach((mainObj: any, index: number) => {

      if (mainObj.allow) {

        let mainMenuObject = new Object;

        let isMenuAccessItem = mainObj.branches.filter(item => item.branchId == branchId);

        if (isMenuAccessItem.length > 0) {

          mainMenuObject['permission'] = isMenuAccessItem[0]['permission'] || '';

          if (mainObj.subMenu) {

            mainObj.subMenu.forEach((subObj: any, idx: number) => {

              if (subObj.allow) {

                let isSubmenuAccessItem = subObj.branches.filter(item => item.branchId == branchId);

                if (isSubmenuAccessItem.length > 0) {

                  mainMenuObject[subObj.childMenu] = isSubmenuAccessItem[0]['permission'] || '';

                }

              }

            });

          }

          returnObject[mainObj.mainMenu] = mainMenuObject;

        }

      }

    });

    return returnObject;

  }

  // Get Menu Branches List
  getMenuBranches(): any {
    if (JSON.parse(this.session('get',"Branches"))) {
      return this.returnMenuBranches();
    }
    else {
      this.branchesIdListItems.subscribe((data: any) => {
        return this.returnMenuBranches();
      });
    }
  }

  returnMenuBranches(): any {

    var mainMenu = this.getMenuNames('mainMenu');

    var subMenu = this.getMenuNames('subMenu');
    
    let branchItems = [];
    
    this.menu.forEach((obj: any, index: number) => {

      // Check Sub Menu is Not Available 

      if (obj.mainMenu == mainMenu && typeof subMenu === 'undefined') {

        branchItems = obj?.branches.map((item: any) => {

          let permissionBranchObj = { 

            'branchId': item.branchId, 

            'branchName': (this.branchList[item.branchId]).toUpperCase(), 

            'permission': item.permission 

          };

          return {...permissionBranchObj, ...this.getCompanyByBranchId(item?.branchId)}

        });

      }

      // Check Sub Menu is Available

      else if (obj.mainMenu == mainMenu && typeof subMenu !== 'undefined') {

        if (typeof obj.subMenu !== 'undefined' && obj.subMenu.length > 0) {

          let subMenuBranchesPermission = 

            obj.subMenu.filter(item => item.childMenu == subMenu).length > 0 ? 

              obj.subMenu.filter(item => item.childMenu == subMenu) : [];              

          branchItems = subMenuBranchesPermission[0]?.['branches']?.map((item: any) => {   

            let permissionBranchObj;  

            if((typeof this.branchList[item.branchId]) !== 'undefined'){

              permissionBranchObj = { 

                'branchId': item.branchId, 

                'branchName': (this.branchList[item.branchId]).toUpperCase(), 

                'permission': item.permission

              }

            }              

            return {...permissionBranchObj, ...this.getCompanyByBranchId(item?.branchId)}

          });

        }

        else if (typeof obj.subMenu == 'undefined') {

          branchItems = obj.branches.map((item: any) => {

            let permissionBranchObj;

            if((typeof this.branchList[item.branchId])){

              permissionBranchObj = { 

                'branchId': item.branchId, 

                'branchName': (this.branchList[item.branchId]).toUpperCase(), 

                'permission': item.permission

              }

            }

            return {...permissionBranchObj, ...this.getCompanyByBranchId(item?.branchId)}

          });

        }

      }

    });

    return branchItems;

  }

  // Get a given menu permissions

  getPermissionFor({ mainMenu = "", subMenuList = [], branchId = "" }:{mainMenu : string, subMenuList : Array<string>, branchId?: string}){

    let menuDet = this.permissionsDet[mainMenu];
      
      subMenuList.forEach((child : string) => {

        menuDet = menuDet.subMenu[child] || {};

      });

      
      return branchId != "" ? (menuDet?.permissions[branchId] || null) :  menuDet?.permissions;

  }

  getBranchDetFromBranchId(branchIdList: Array<string>) {

    return _.filter(JSON.parse(this.session('get','Branches')),(value)=> branchIdList.includes(value.branchId))

  }


  // Get path permissions for given

  public async getPermissions({ pathArr = [], isNeedBranchList = false } : { pathArr?: Array<any>, isNeedBranchList?: boolean }) {

    let permissionDetails = JSON.parse(this.session('get',"permissionDetails"));

    let menuPermissionDet: any, permissionDet: any = {}, branchList: any = [];

    if( pathArr.length==1 ) {

      permissionDet = permissionDetails[pathArr[0]]['permissions'] || {};

    } else {

      pathArr.forEach((menuName: any, index: number) => {

        menuPermissionDet = index==0 ? permissionDetails[menuName] : menuPermissionDet[menuName];

        index<pathArr.length-1 ? menuPermissionDet = menuPermissionDet.subMenu || {} : '';
        
      });

      permissionDet = menuPermissionDet?.permissions || {};

    }

    if ( isNeedBranchList ) {

      branchList = Object.keys(permissionDet).map((branchId: any) => {

        let permissionBranchObj = { 

          'branchId': branchId,

          'permission': permissionDet[branchId],

          'branchName': (this.branchList[branchId]).toUpperCase()

        };

        return {...permissionBranchObj, ...this.getCompanyByBranchId(branchId)}

      });

      return branchList;

    }

    return permissionDet;

  }

  getTodayDate(): any {
    return new Date().toISOString().slice(0, 10);
  }

  setMaxDate() {
    const today = new Date();
    today.setDate(today.getDate() + 7);
    this.maxDate = today.toISOString().slice(0, 10);
    return this.maxDate;
  }

  setMaxYear() {
    var d = new Date();
    var year = d.getFullYear();
    var month = d.getMonth();
    var day = d.getDate();
    this.maxYear = new Date(year + 1, month, day);
    return this.maxYear.toISOString().slice(0, 10);
  }

  // Clear Local Storage When User Logout 
  logout() {
    this.menu = [];
    this.router.navigate(["/" + this.auth?.parentCompanyId || '']);
    sessionStorage.clear();
    return true;
  }

  // When User Logged In Store The User Token In Local Storage
  login(token: string): any {
    this.session('set','activateUser', JSON.stringify(jwt_decode(token)));
    this.auth = JSON.parse(this.session('get','activateUser'));
    this.getNotificationActions();
    return this.auth;
  }

  getNotificationActions() {
    this.getService("/comm/notificationActions").subscribe((res:any)=>{
      if(res.status==200) {
        this.session("set","notificationActions",JSON.stringify(res.results));
      }
    },(err: any)=>{

    });
  }

  // Set/Get/Remove for Session storage
  session(method: string, key: string, value?: any) {
    if(method === "get") {
      let sessionData = sessionStorage.getItem(key);  
      return sessionData ? 
          environment.encryptedReq ? this.apiservice.decryptData(sessionData) : sessionData : null;
    }
    else if(method === "set"){
      environment.encryptedReq ? value = this.apiservice.encryptData(value) : "";
      sessionStorage.setItem(key,value);
    }
    else if(method === "remove") 
      sessionStorage.removeItem(key);
  }

  // POST API Method While Pass JSON Data
  postService(url: string, data?: any, loaderState?: any, params?: any): any {
    if (typeof loaderState !== 'undefined' && loaderState.showLoader) {
      this.loaderApiUrls.subscribe(item => {
        item.push(this.apiservice.baseUrl + url);
      });
    }
    return this.apiservice.postService(url, data, params);
  }

  // UPDATE API Method
  updateService(url: string, data?: any, params?: any): any {
    return this.apiservice.updateService(url, data, params);
  }

  // GET API Method  
  getService(url: string, params?: any): any {
    return this.apiservice.getService(url, params);
  }

  // DELETE API Method
  deleteService(url: string): any {
    return this.apiservice.deleteService(url);
  }

  // POST Method While Pass Form Data
  postFile(url: string, formData): any {
    return this.apiservice.postFile(url, formData);
  }

  // GET Method While Getting File
  getFile(url: string): Observable<Blob> {
    return this.apiservice.getFile(url);
  }

  // Download All Files In This Application
  downloadFiles(fileName, fileExtension, fileURL) {
    this.apiservice.downloadFiles(fileName, fileExtension, fileURL);
  }

  // No Records For Download Error Alert
  showNoRecordsAvailable() {
    this.toastr.info("Sorry no records available to download");
  }

  // Show the Toast Message 
  showToastr(message: string, messageType: string, title?: 'success' | 'error' | 'warning' | 'info') {
    var customClass: string = '';
    switch (messageType) {
      case 'success':
        customClass = 'success-toastr mt-4';
        break;
      case 'error':
        customClass = 'error-toastr mt-4';
        break;
      case 'warning':
        customClass = 'wanring-toastr mt-4';
        break;
      case 'info':
        customClass = 'information-toastr mt-4';
        break;
      default: 
        customClass = 'success-toastr mt-4';
        break;
    }
    // without Title
    if (message && !title) {
      this.toastr.show(message, '', { timeOut: 2000 }, customClass);
    }
    // with Title 
    else if (message && title) {
      this.toastr.show(message, title, { timeOut: 2000 }, customClass);
    }
  }

  showError(error) {    

    if (error.error) {

      if (error.error.error) {

        if (error.error.error.code == 11000) {

          return this.showToastr('Duplicate record found!', 'error');

        }
        
      }
      else if(error.status == 409){

        return (error.error.message == "Data mismatch!") ?   this.showToastr("Data Mismatched, Please open again!","info") :  this.showToastr(error.error.message, "info");

      }
       else {
         
        return this.showToastr('Operation Failure!', 'error');

      }
    } else {
      return this.showToastr('Operation Failure!', 'error');
    }
  }

  // Convert UTC time ISO time format 
  UTCtoISO(value: string, format?: string): any {
    if (!value) {
      return '';
    }
    const dateValue = new Date(value);
    let dateWithNoTimezone = new Date(
      dateValue.getUTCFullYear(),
      dateValue.getUTCMonth(),
      dateValue.getUTCDate(),
      dateValue.getUTCHours(),
      dateValue.getUTCMinutes(),
      dateValue.getUTCSeconds()
    );
    // If no format is needed return ISO time
    if (typeof format == 'undefined') {
      return dateWithNoTimezone;
    }
    // If any format is needed convert to that format using Angular common Datepipe    
    else {
      return this.datePipe.transform(dateWithNoTimezone, format);
    }
  }

  // Change The Date Format Using DatePipe
  datePipeTransform(date: any, format: string) {
    let transformedDate: any = "";
    if (date !== null && new Date(date).toString() != 'Invalid Date') {
      let dateValue = typeof date == "string" ? date.replace(/[z,Z]/g, '') : date;
      transformedDate = this.datePipe.transform(new Date(dateValue), format);
    }
    return transformedDate;
  }

  // Change The Date to ISO Format
  convertToISOFormat(date: any): any {
    let datedet = this.datePipe.transform(date, "yyyy-MM-dd'T'HH:mm:ss.SSS");
    return datedet;
  }

  // to identify the difference between two array of objects, in arr1 not in arr2 based on field/key value by @ajith
  findArrayObjFilter(arr1, arr2, field) {
    return arr1.filter(function (obj) {
      return !arr2.some(function (obj2) {
        return obj[field] == obj2[field];
      });
    });
  }


  // Search array of objects by a string value
  searchFilter(arrObj, searchData: string) {

    arrObj = arrObj.filter((o: any) =>

      Object.keys(o).some(k =>
        o[k]?.toString().replace(/\s/g, '').toLowerCase().includes(searchData.toString().replace(/\s/g, '').toLowerCase()))).concat();

    return arrObj.concat();
  }

  // Remove duplicate objects from Array
  removeDuplicateArrofObj(arrObj, fieldName) {
    const filteredArr = arrObj.reduce((acc, current) => {
      const x = acc.find(item => item[fieldName] === current[fieldName]);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);
    return filteredArr;
  }

  // Get The All Branches List When The Site Loaded 
  getBranchList() {
    this.listBranchId = [];
    this.companyDetails.companies.forEach((company : any)=>{
      company.branches.forEach((branch : any)=>{
        let data = [];
        data.push({branchName : branch?.branchName, branchId : branch?.branchId, companyName : company?.companyName, companyId : company?.companyId});
        this.listBranchId = [...this.listBranchId,...data];
      });
    });
    
    this.session('set','Branches', JSON.stringify(this.listBranchId));
    this.branchesIdListItems.next(this.listBranchId);
    this.loadEnumerators();
  }


  // Get The All Available Team List
  getTeamList(branches) {
    this.postService("/hrSettings/listTeam", branches).subscribe((res: any) => {
      if (res.status == 200) {
        this.teamItems.next(res.results);
        this.teamList = res.results;
        let teamListObj = new Object();
        this.teamList.forEach((obj: any, index: number) => {
          teamListObj[obj._id] = obj.name;
        });
        this.teamListItems = teamListObj;
      }
      else {
        this.teamItems.next([]);
      }
    }, err => {
      this.showToastr('Data Fetching Error!', 'error');
    }
    );
  }

  //Share user data between organization to user
  editUserData(userData) {
    this.editUsermode = true;
    this.editUserItems.next(userData);
  }

  bookingData(data) {
    this.bookingDataList.next(data);
  }

  showMobileViewSidenavButton(branchDetails: any) {
    this.sidenavButtonDet.next(branchDetails);
  }

  downloadExcel(url, data, filename) {
    delete data.pageIndex;
    delete data.pageSize;
    this.postService(url, data).subscribe(res => {
      if (res.status == 200) {
        this.exportToExcel(res.results, filename);
        this.showToastr("Report Generated success!", 'success');
      }
    }, error => {
      this.showToastr("Report Generated Error!", 'error');
    });
  }

  exportToExcel(data, filname) {
    const fileName = filname + '.xlsx';
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, filname);
    XLSX.writeFile(wb, fileName);
  }

  exportToExcelReport(data, filname, dateHeaders? : any) {
    const fileName = filname + '.xlsx';
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
        
    if(dateHeaders.length > 0 && typeof dateHeaders[0] != "undefined"){
      XLSX.utils.sheet_add_json(ws,  dateHeaders, {skipHeader: false, origin: "A1", cellStyles : true});
      XLSX.utils.sheet_add_json(ws, data, {skipHeader: false, origin: "A4"});
    }
    else{
      XLSX.utils.sheet_add_json(ws, data, {skipHeader: false, origin: "A1"});
    }
    const wb: XLSX.WorkBook = XLSX.utils.book_new(); 
    XLSX.utils.book_append_sheet(wb, ws, filname);
    XLSX.writeFile(wb, fileName);
  }
 

  exportXlsx(dataHeadersList : any, data:any, filename : string, filterValues?: any)
  {
 
    let fileName = filename+" ("+this.getTodayDate()+").xlsx";
    var dualHeaderStatus : boolean = false;
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(this.getTodayDate(), { views: [{showGridLines: true}] });
    worksheet.columns = dataHeadersList;
    worksheet.addRows(data);
    if(typeof filterValues != "undefined"){
      dualHeaderStatus = true;
      worksheet.spliceRows(1,0,Object.keys(filterValues)); // for headers
      worksheet.spliceRows(2,0,Object.values(filterValues)); // for values
      worksheet.spliceRows(3,0,new Array());
    }
    else{
      dualHeaderStatus = false;

    }
    
    this.excelFormatting(worksheet, dualHeaderStatus);
    // Generate & Save Excel File
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, fileName);
    })
    
  }

  excelFormatting(worksheetName, dualHeaderStatus){
    worksheetName.eachRow({ includeEmpty: true }, function(row, rowNumber) {
        row.eachCell(function(cell, colNumber){
            if(rowNumber==1 || rowNumber==4 && dualHeaderStatus){
                cell.fill = {
                    type: 'pattern',
                    pattern:'solid',
                    fgColor:{argb:'8DB4E2'}
                };
            }
            else if(rowNumber==1){
              cell.fill = {
                type: 'pattern',
                pattern:'solid',
                fgColor:{argb:'8DB4E2'}
            };
            }
            cell.alignment  = { horizontal: 'center' };
            cell.border = {
                top: {style:'thin'},
                left: {style:'thin'},
                bottom: {style:'thin'},
                right: {style:'thin'}
            };
        });
    });
}

  exportToMultipleSheets(sheet1, sheet2, filname) {
    const fileName = filname + '.xlsx';
    const ws1: XLSX.WorkSheet = XLSX.utils.json_to_sheet(sheet1);
    const ws2: XLSX.WorkSheet = XLSX.utils.json_to_sheet(sheet2, { header: sheet2 });
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws1, "variants");
    XLSX.utils.book_append_sheet(wb, ws2, "charge_categories");
    XLSX.writeFile(wb, fileName);
  }

  importExcel(data, filename) {
    const url = "/upload/excel/" + filename
    return this.apiservice.postFile(url, data)
  }

  permissionCheck() {
    if (!this.menu || this.menu.length == 0) {
      this.menu = JSON.parse(this.session('get',"Permissions"));
    }
    var pathArray = this.location.path().split('/');
    for (var i = 0; i < this.menu.length; i++) {
      if (pathArray[2] == this.menu[i].mainMenu) {
        this.menuWiseBranches(this.menu[i], pathArray);
        if (this.menu[i].subMenu) {
          for (var j = 0; j < this.menu[i].subMenu.length; j++) {
            if (pathArray.length == 4) {
              if (pathArray[3] == this.menu[i].subMenu[j].childMenu) {
                if (this.menu[i].subMenu[j].allow != false) {
                  return this.menu[i].subMenu[j].allow;
                }
              }
            } else {
              return this.menu[i].allow
            }
          }
        } else {
          return this.menu[i].allow
        }
      } else {
        return this.menu[i].allow
      }
    }
  }

  menuWiseBranches(menuBranch, path) {
    if (!this.listBranchId) {
      this.listBranchId = JSON.parse(this.session('get',"Branches"));
    }
    if (menuBranch.subMenu && path.length > 3) {
      menuBranch.subMenu.forEach(x => {
        if (x.allow == true && x.childMenu == path[3]) {
          var arr3 = x.branches.map((item, i) => Object.assign({}, item, this.listBranchId[i]));
          this.menuBranchList = arr3.filter((x) => {
            if (x.permission != 'block') {
              return x
            }
          });
        }
      });
    } else {
      var arr2 = menuBranch.branches.map((item, i) => Object.assign({}, item, this.listBranchId[i]));
      this.menuBranchList = arr2.filter((x) => {
        if (x.permission != 'block') {
          return x
        }
      });
    }
  }

  // Get a current component permissions by router with mainMenu
  getMenuPermissons() {
    var pathArray = this.location.path().split('/');

    for (var i = 0; i < this.menu.length; i++) {
      if (pathArray[2] === this.menu[i].mainMenu) {
        return this.menu[i].subMenu;
      }
    }
    return null;
  }

  newNotificationReceived(message) {
    this.notificationList.next(message)
  }

  playAudio() {
    if (this.playNotificationAudio) {
      this.notificationAudio.load();
      this.notificationAudio.play();
    }
  }

  // ----------------------------Conversion of Sheet to Json-----------(Superb tutorial, Dont delete)

  // importExcel(data, filename) {
  //   let workBook = null;
  //   let jsonData = null;
  //   let fileReader = new FileReader();
  //   fileReader.onload = (e) => {
  //     var arrayBuffer = fileReader.result;
  //     workBook = XLSX.read(arrayBuffer, { type: 'binary' });
  //     jsonData = workBook.SheetNames.reduce((initial, name) => {
  //       const sheet = workBook.Sheets[name];
  //       initial[name] = XLSX.utils.sheet_to_json(sheet);
  //       return initial;
  //     }, {});
  //     const dataString = JSON.stringify(jsonData);
  //     let url = "/upload/excel/" + filename;
  //     this.postFile(url, dataString).subscribe(res => {
  //       if (res.status == 200) {

  //       }
  //     }, error => {

  //     })
  //   }
  //   fileReader.readAsBinaryString(data);
  // }
  // ----------------------------Conversion of Sheet to Json-----------(Superb tutorial, Dont delete)

  getMenuNames(menuType: string): string {
    var pathArray = this.location.path().split('/');
    var name = menuType == 'mainMenu' ? pathArray[2] : menuType == 'subMenu' ? pathArray[3] : menuType == 'subMenuChild' ? pathArray[4] : '';
    return name;
  }

  getBranchAccessDetails(selectedBranchesArr: Array<string>, menuType: string): any {
    this.selectedBranches = [];
    this.selectedbranchAccessDet = new Object();
    this.selectedBranches = selectedBranchesArr;
    this.selectedBranches.forEach((branchName: string, i: number) => {
      this.selectedbranchAccessDet[branchName] = menuType == 'mainMenu' ?
        this.branchAccessDetails[branchName][this.getMenuNames(menuType)]['permission'] :
        this.branchAccessDetails[branchName][this.getMenuNames('mainMenu')][this.getMenuNames(menuType)];
    });
    return this.selectedbranchAccessDet;
  }

  filterStatusListByFinanceOption(financeOption: string, financeOptionsList: any) {
    return financeOptionsList.filter((obj: any) => obj?.type == financeOption)[0]?.status;
  }

  // For sharing the booking submenu permissions
  bookingSubmeuPermission(bookingPermissions, branches) {
    this.bookingSubMenuItem.next({ permissions: bookingPermissions, branchList: branches });
  }

  updateEditBookingData(data?: any): void {
    this.editBookingData = data;
  }

  bookingStatusValidation(bookingData: any, statusDet: any, updatedBokingData?: any): Array<any> {
    let opportunityId = updatedBokingData && typeof updatedBokingData.opportunityId !== 'undefined' ? updatedBokingData.opportunityId : bookingData?.opportunityId || '';
    let isAllPaymentsApproved = bookingData.paymentList && bookingData.paymentList.length>0 ? bookingData.paymentList.every(item=>item.paymentStatus!="pending") : true;
    let customerPan = updatedBokingData && typeof updatedBokingData.customerPan !== 'undefined' ? updatedBokingData.customerPan : bookingData?.customerPan || '';
    let bookingVerified = updatedBokingData && typeof updatedBokingData.bookingVerified !== 'undefined' ? updatedBokingData.bookingVerified : bookingData?.bookingVerified || '';
    let expectedDeliveryDate = updatedBokingData && typeof updatedBokingData.expectedDeliveryDate !== 'undefined' ? updatedBokingData.expectedDeliveryDate : bookingData?.expectedDeliveryDate || '';
    let expectedPaymentDate = updatedBokingData && typeof updatedBokingData.financeInfo !== 'undefined' && typeof updatedBokingData.financeInfo.expectedPaymentDate !== 'undefined' ? updatedBokingData.financeInfo.expectedPaymentDate : bookingData?.financeInfo?.expectedPaymentDate || '';
    let documents = updatedBokingData && typeof updatedBokingData.docs !== 'undefined' ? updatedBokingData.docs : bookingData?.docs && (bookingData.docs.map((docsDet: any) => docsDet.collected)).every((val) => val);
    let additionalDocuments = updatedBokingData && typeof updatedBokingData.addDocs !== 'undefined' ? updatedBokingData.addDocs : bookingData?.addDocs && (bookingData.addDocs.map((docsDet: any) => docsDet.collected)).every((val) => val);
    let financier = updatedBokingData && typeof updatedBokingData.financeInfo !== 'undefined' && typeof updatedBokingData.financeInfo.financier !== 'undefined' ? updatedBokingData.financeInfo.financier : bookingData?.financeInfo?.financier || '';
    let vehicleRegNo = updatedBokingData && typeof updatedBokingData.vehicleRegNo !== 'undefined' ? updatedBokingData.vehicleRegNo : bookingData?.vehicleRegNo || '';
    let loanAmount = updatedBokingData && typeof updatedBokingData.financeInfo !== 'undefined' && typeof updatedBokingData.financeInfo.appliedLoanAmount !== 'undefined' ? updatedBokingData.financeInfo.appliedLoanAmount : bookingData?.financeInfo?.appliedLoanAmount || 0;
    let orderValue = this.calculateByRoadPrice(bookingData, 'byRoadPrice');
    let paymentList = this.calculateByRoadPrice(bookingData, 'totalPayment');
    let invoicedDate = updatedBokingData && typeof updatedBokingData.invoiceDetails !== 'undefined' && typeof updatedBokingData.invoiceDetails.invoiceDate !== 'undefined' ? updatedBokingData.invoiceDetails.invoiceDate : bookingData?.invoiceDetails?.invoiceDate || '';
    let invoiceNumber = updatedBokingData && typeof updatedBokingData.invoiceDetails !== 'undefined' && typeof updatedBokingData.invoiceDetails.invoiceNumber !== 'undefined' ? updatedBokingData.invoiceDetails.invoiceNumber : bookingData?.invoiceDetails?.invoiceNumber || '';
    let isVehicleAssigned = bookingData && typeof bookingData.assignedId != 'undefined';
    loanAmount = this.utils.commaToNumber(null, null, loanAmount || 0);
    orderValue = this.utils.commaToNumber(null, null, orderValue || 0);
    
    if (eval(statusDet.conditions) || statusDet.description == 'Cancelled' || statusDet.description == 'DMS Verification Pending') {
      return [];
    } else {
      let mismatchConditionList = [];
      statusDet.conditions.split('&&').forEach((condition: string) => {
        if (!eval(condition.trim())) {
          mismatchConditionList.push({ condition: condition.trim(), conditionStatus: false, msg_content: bookingStatusMessages[condition.trim()] });
        }
      });
      return mismatchConditionList;
    }
  }


  // RTO Status Automatic Change by given RTA CheckList
  RTOStatusValidation(checklist:any, bookingData:any) {
    let statusDet = JSON.parse(this.session("get","rtoStatus"))[bookingData.branchId];
    let currentStatus = bookingData?.rtoStatus || "";    
    
    for(var i=0;i<=statusDet?.length;i++) {      
      if(!eval(statusDet[i]?.conditions)) {
        // Store previous RTA status
        currentStatus = statusDet[i>0? i-1 : 0]?.description;
        break;
      }
    }
    
    return currentStatus;
  }

  // Construct price charges checked condition for check and uncheck
  constructPriceWithCond(formData ?: any) : any {

    let exShowroom = this.currentpriceDet.filter((charge:any) => (charge?.chargeGroup == 'ex-showroom'))?.[0]?.value || null;

    let tempRegistration = (formData && formData.tempReg);
    
    this.currentpriceDet.forEach((charge:any) => {

        if(charge?.condition){
          
          charge['conditionRes'] = eval(charge.condition);
          
        }
    });

    return _.cloneDeep(this.currentpriceDet);
  }

  // Get Total Discounts For Dealer or Other Offers Discounts
  public getTotalDiscounts(bookingData: any, discountType: string): any {
    let totalDiscounts = {
      oemContribution: 0,
      dealerContribution: 0,
      totalContribution: 0,
    };
    if (typeof bookingData[discountType] != 'undefined' && bookingData[discountType].length > 0) {
      bookingData[discountType].forEach((offer: any, index: number) => {
        totalDiscounts.oemContribution += this.utils.currencyToFloatNumber(offer.oemContribution);
        totalDiscounts.dealerContribution += this.utils.currencyToFloatNumber(offer.dealerContribution) - this.utils.currencyToFloatNumber(offer?.offerVariance || 0);
        totalDiscounts.totalContribution += this.utils.currencyToFloatNumber(offer.totalContribution) - this.utils.currencyToFloatNumber(offer?.offerVariance || 0);
      });
    }    
    return totalDiscounts;
  }

  //Calculate On Road Price 
  public getOnRoadPrice(bookingData: any, neededValue?: string): number {

    let totalOtherCharges = 0;
    let totalPriceOnly = 0;
    let onRoadPrice = 0;
    
    bookingData?.otherCharges && bookingData.otherCharges.length > 0 && bookingData.otherCharges.forEach((charge: any) => {
      totalOtherCharges += charge?.amount;
    });
    if ( bookingData?.price && bookingData.price.length > 0  && typeof bookingData.price[bookingData.price.length - 1]['totalPrice'] !== 'undefined' && bookingData.price[bookingData.price.length - 1]['totalPrice']) {
      totalPriceOnly = bookingData.price[bookingData.price.length - 1]['totalPrice'];
    } 
    else {
      bookingData?.price && bookingData.price.length > 0 && Object.keys(bookingData.price[bookingData.price.length - 1]).forEach((item) => {
        if (item !== 'deleted' && item !== 'endDate' && item !== 'effectiveDate' && item !== '_id' && item !== 'totalPrice' && item !== 'onRoadPrice' && bookingData.price[bookingData.price.length - 1][item]) {
          totalPriceOnly +=  this.utils.currencyToFloatNumber(bookingData.price[bookingData.price.length - 1][item]);
        }
      });
    }

    onRoadPrice = totalOtherCharges + totalPriceOnly;

    return neededValue=='priceOnly' ? totalPriceOnly : neededValue=='otherCharges' ? totalOtherCharges : onRoadPrice;
  }

  // Calculate the By Road Price 

  public calculateByRoadPrice(bookingData: any, neededValue?: string): number {

    let corparateOfferDet = bookingData.corporateStandardOffer && bookingData.corporateStandardOffer[bookingData.corporateStandardOffer.length-1] || {};
    let consumerOfferDet = bookingData.consumerStandardOffer && bookingData.consumerStandardOffer[bookingData.consumerStandardOffer.length-1] || {};
    let exchangeOfferDet = bookingData.exchangeOffers && bookingData.exchangeOffers[bookingData.exchangeOffers.length-1] || {};

    // Calculation Fromula -->( consumerStandardOffer + corporateStandardOffer + exchangeOffers + Dealer Discounts + Other Discounts  )
    let totalConsumerStandardOffer = consumerOfferDet ? (this.utils.currencyToFloatNumber(consumerOfferDet.totalContribution) - this.utils.currencyToFloatNumber(consumerOfferDet?.offerVariance || 0)) : 0;
    let totalCorporateStandardOffer = corparateOfferDet ? (this.utils.currencyToFloatNumber(corparateOfferDet.totalContribution) - this.utils.currencyToFloatNumber(corparateOfferDet?.offerVariance || 0)) : 0;    
    let totalExchangeOffer = corparateOfferDet ? (this.utils.currencyToFloatNumber(exchangeOfferDet.totalContribution) - this.utils.currencyToFloatNumber(exchangeOfferDet?.offerVariance || 0)) : 0;    
    let dealerDiscounts = this.getTotalDiscounts(bookingData, 'dealerDiscounts')?.totalContribution;
    let otherDiscounts = this.getTotalDiscounts(bookingData, 'otherDiscounts')?.totalContribution;
    let ageingOffer = bookingData?.ageingAmount || 0;
    let grandTotalOffer = totalConsumerStandardOffer + totalCorporateStandardOffer + totalExchangeOffer + dealerDiscounts + otherDiscounts + ageingOffer;
    var byroadPrice = this.getOnRoadPrice(bookingData) - grandTotalOffer;

    // Calculate User Total Payments                                
    let grandTotalPayment = 0;
    bookingData?.paymentList && bookingData.paymentList.forEach((paymentDet: any) => {
      if(paymentDet?.paymentStatus=='approved'){
        grandTotalPayment += this.utils.currencyToFloatNumber(paymentDet?.payment);
      }
    });

    // Final Pending Amount
    let finalPendingAmount = byroadPrice - this.utils.currencyToFloatNumber(grandTotalPayment);
    return neededValue == 'byRoadPrice' ? byroadPrice : neededValue == 'totalPayment' ? grandTotalPayment : neededValue == 'pendingAmount' ? finalPendingAmount : neededValue == 'grandTotalOffer' ? grandTotalOffer : 0;
  }

  public calculateCollectionDet(collectionDet: Array<any>, neededValue: string) {
    if(neededValue=="achievedCollection") {
      let totalReceivedAmount = 0;
      totalReceivedAmount = collectionDet.length ? collectionDet.reduce((a, b) => a + (b['collectedAmount'] || 0), 0) : 0;
      return totalReceivedAmount
    } 
    else if(neededValue=="targetCollection") {
      let targetTargetCollection = 0;
      targetTargetCollection = collectionDet.reduce((a, b) => a + (b['targetAmount'] || 0), 0); 
      return targetTargetCollection
    }
    else if(neededValue=="missingCollection") {
      let targetMissingCollection = 0;
      collectionDet.forEach((obj: any,index: number)=>{
        if(Date.parse(this.getTodayDate())>Date.parse(obj.expectedDate)) {
          targetMissingCollection += obj.receivedPayment || 0;
        }
      });
      return targetMissingCollection
    }    
  }

  getOfferValues(offerDet: any) {
    delete offerDet[0].values['deleted'];
    offerDet[0].values['offerType'] = offerDet[0].offerType;
    offerDet[0].values['totalContribution'] = (offerDet[0].values['oemContribution'] || 0) + (offerDet[0].values['dealerContribution'] || 0);
    return offerDet[0].values;
  }

  getCurrentBookingStatus(bookingData: any): string {
    return typeof bookingData.bookingStatus == 'string' ? bookingData.bookingStatus : bookingData.bookingStatus[bookingData.bookingStatus.length - 1].name;
  }


  // CompanyID dropdown construction only for permitted branches 
  getCompanyByBranchId(branchId){

   let result = this.listBranchId.filter((branch : any)=> (branch?.branchId == branchId))[0];

    return {companyId : result?.companyId, companyName : result?.companyName}
  }

  // Array to Object 
  arrayToObject(key : string, data : any){    

    var value = _.cloneDeep(data);
    delete value[key];
    var output = [];
    
    data[key].forEach((item:any)=>{
      var tempObj = {};
      tempObj[key] = item;
      if(key === "branchId") {
        tempObj['companyId'] = this.getCompanyByBranchId(item).companyId;
      }
      tempObj['parentCompanyId'] = this.auth.parentCompanyId;
      tempObj = {...value,...tempObj};
      output.push(tempObj)
    });
    return output;
    
  }

  sortArrayofObjects(arrValue: any, keyValue: String ): Array<any> {
    return _.sortBy(arrValue, (e: any)=>{
      return e[eval("keyValue")];
    })
  }

  // Return array of objects only the field matches value
  //companyID, company array, branchlist
  filterArrayOfObjectByField(key : any,value : any, arrObj : any){
    return  arrObj.filter(item => value.includes(item[key]));
  }

  // Get Excel address
  getSpreadSheetCellNumber(row, column) {
    row = row-1;
    column = column-1;
    let result = '';

    // Get spreadsheet column letter
    let n = column;
    while (n >= 0) {
      result = String.fromCharCode(n % 26 + 65) + result;
      n = Math.floor(n / 26) - 1;
    }
  
    // Get spreadsheet row number
    result += `${row + 1}`;
  
    return result;
  };  


  // Convert Hour to Minute (ex 1:00 to 60)
  convertH2M(timeInHour: string){  
    

    console.log(timeInHour);

    if(timeInHour){

      var timeParts = timeInHour.split(":");
  
      console.log(Number(timeParts[0]) * 60 + Number(timeParts[1]));
      
      return Number(timeParts[0]) * 60 + Number(timeParts[1]);
    }
    

}

// Convert Hour to Minute (ex 60 to 1:00)
convertM2H(minutes: number) {

    var hours = Math.trunc(minutes/60);

    minutes = minutes % 60;

    return (hours.toString().padStart(2,"0")) +":"+ (minutes.toString().padStart(2,"0"));

}

}

export enum branchAccessDetails { }

export enum companyList { }

export enum branchList { }

export enum teamListItems { }

export enum bookingStatusMessages {
  "opportunityId" = "Opportunity ID is required",
  "isAllPaymentsApproved" = "Please ensure all payments are approved",
  "customerPan" = "Customer passport number is required",
  "bookingVerified" = "Booking Verification required",
  "expectedDeliveryDate" = "Expected delivery date is required",
  "expectedPaymentDate" = "Expected payment date is required",
  "documents" = "Pending documents yet to be collected",
  "additionalDocuments" = "Pending additional documents yet to be collected",
  "financier" = "Financier name is required",
  "loanAmount>0" = "Loan amount is required",
  "(orderValue - paymentList - loanAmount)<=0" = "Payment received including any loan applied should be higher than By Road Price",
  "(orderValue - paymentList - loanAmount)>0" = "Payment received including any loan applied should be equal to or higher than By Road Price",
  "orderValue<=paymentList" = "Payment received cannot be lower than By Road Price",
  "invoicedDate" = "Invoice date is required",
  "invoiceNumber" = "Invoice number is required",
  "vehicleRegNo" = "Vehicle registration number is required",
  "loanAmount<=paymentList" = "Loan amount not yet received",
  "isVehicleAssigned" = "Vehicle is not assigned to this booking",
}
