import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs';
import { CommonService } from '../services/common.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {
  auth: any;
  userRole: Number;
  user: any;
  mainMenu: string[];

  constructor(private service: CommonService, private router: Router) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let token = this.service.session('get','Authorization');
    let components = this.service.menu;
    if (!token || token == 'null' || token == undefined) {
      this.service.logout();
      return false;
    } else {
      return true;
    }
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    let token = this.service.session('get','Authorization');
    let components = this.service.menu;
    if (token) {
      var mainMenu = state.url.split('/')[2];
      let menuDet = components.filter((item: any)=>mainMenu == item.mainMenu && item.allow);
      if(menuDet.length==0) {
        this.router.navigate(['/root/403']);
        return false;
      } else {        
        let submenuDet = menuDet[0].subMenu && menuDet[0].subMenu.length>0 ? menuDet[0].subMenu.filter((item: any)=>item.allow) : [];
        submenuDet.length>0 && state.url.split('/').length==3 && state.url.split('/')[2]!=='reports'  ? this.router.navigate(['/root/'+mainMenu+'/'+submenuDet[0].childMenu]) : "";
        return true;
      }
    }
    else {
      this.service.logout();
      return false;
    }
  }

}
