import { Injectable } from "@angular/core";
import { FormGroup, FormControl } from "@angular/forms";

@Injectable({
  providedIn: "root",
})
export class UtilsService {
  constructor() {}

  public numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  public alphaOnly(event) {
    var e = event.which;
    if (
      (e > 64 && e < 91) ||
      (e > 96 && e < 123) ||
      e == 32 ||
      e == 8 ||
      e == 46
    ) {
      return true;
    }
    return false;
  }

  //Restrict special char created by ajith
  public alphaNumericOnly(event) {
    var k;
    k = event.charCode;

    return (
      (k > 64 && k < 91) ||
      (k > 96 && k < 123) ||
      k == 8 ||
      k == 32 ||
      k == 95 ||
      (k >= 48 && k <= 57)
    );
  }

  //only allow the keys(#, num, a-f)
  public Hexcheck(event)
  {
  var k = event.charCode;
  
  return ((k > 96 && k < 103) || (k >= 48 && k < 58)|| k == 8 || k == 35);
  }

  public restrictKeys(event)
  {
  var k = event.charCode;
  
  return false;
  }


  public commaToNumber(formGroup?: FormGroup, formControl? : any, value? : any) {
    if(formGroup && formControl){
      let amount = formGroup.get(formControl).value || 0;
      if (amount) {
          formGroup.get(formControl).setValue(typeof amount == "string" ? parseInt(amount.replace(/[^0-9-.]/g, "")) : amount);
      } else {
        formGroup.get(formControl).setValue(0);
      }
    } else {
      let amount = value;
      return typeof amount == "string" ? parseInt(amount.replace(/[^0-9-.]/g, "")) : amount;
    }
  }

  public validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        return control.markAsDirty({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        return this.validateAllFormFields(control);
      }
    });
  }

  public commaReplace(value) {
    const b = (value || 0).toString().replace(/[^0-9-.]/g, "");
    return parseInt(b);
  }

  public currencyFormat(formGroup: FormGroup, formControl) {
    const c = (formGroup.get(formControl).value || 0)
      .toString()
      .replace(/[^0-9-.]/g, "")
      .replace(/(\d+?)(?=(\d\d)+(\d)(?!\d))(\.\d+)?/g, "$1,");
    if (c) {
      const b = "\u20B9";
      formGroup.get(formControl).setValue(b.concat(c));
    }
  }

  public singleCurrencyFormat(value) {
    const d = (value || 0)
      .toString()
      .replace(/(\d+?)(?=(\d\d)+(\d)(?!\d))(\.\d+)?/g, "$1,");
    const b = "\u20B9";
    return b.concat(d);
  }

  public currencyCommaSperator(value) {
    const d = (value || 0)
      .toString()
      .replace(/(\d+?)(?=(\d\d)+(\d)(?!\d))(\.\d+)?/g, "$1,");
    return d;
  }

  public convertToNumber(value)
  {
    return isNaN( Number(value) ) ? 0 :  Number(value);
  }

  public convertToCamelCase( value : string ) : string
  {
    return value.trim()  //might need polyfill if you need to support older browsers
      .toLowerCase()  //lower case everything
        .replace(/([^A-Z0-9]+)(.)/ig, //match multiple non-letter/numbers followed by any character
          function(match) { 
            return arguments[2].toUpperCase();  //3rd index is the character we need to transform uppercase
          }
        )
  }

  public stripSpacesWithLowercase( value : string ) : string
  {
    return value.toLowerCase().replace(/\s/g, '');
  }

  public getJsDateFromExcel(excelDate : any) : Date {
    // JavaScript dates can be constructed by passing milliseconds
    // since the Unix epoch (January 1, 1970) example: new Date(12312512312);
    // 1. Subtract number of days between Jan 1, 1900 and Jan 1, 1970, plus 1  (Google "excel leap year bug")             
    // 2. Convert to milliseconds.

    let d = new Date();

    if(typeof excelDate=='number' && excelDate !=0) {

      d = new Date(((excelDate - (25567 + 1)-1)*(86400*1000)));

    } else if(typeof excelDate=='string' && excelDate !='') {

      if(excelDate.includes('/')) {
        
        let dateArr = excelDate.split('/');
        
        d = new Date(dateArr[1]+'-'+dateArr[0]+'-'+dateArr[2]);

      } else if(excelDate.includes('-')) {
        
        let dateArr = excelDate.split('-');
        
        d = new Date(dateArr[1]+'-'+dateArr[0]+'-'+dateArr[2]);

      }
      
    }

    return  d;

  }

  public smartbridgePurcStatus = (tmlPurcStatus) => ({

    "billed": "billed",

    "in transit": "in transit",
    
    "sold": "billed",

    "received - ok": "received",

    "delivered": "received",

    "delivered to customer": "received",

  })[tmlPurcStatus];
  

  public currencyToFloatNumber(value:any)
  {
    if(typeof value === 'undefined') return 0
    if(typeof value==="number") {
      return Number(value.toFixed(2));
    }
    return Number(Number(value.replace(/,/g,'')).toFixed(2));
  }

  public sortObjectKeysByValue(object, type: 'ASC' | 'DSC'): Object {
    return Object.entries(object).sort(([,a]: any,[,b]: any) =>{ return type=='ASC' ? a-b : b-a }).reduce((r, [k, v]) => ({ ...r, [k]: v }), {})
  }
}
